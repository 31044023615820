import React from 'react';
import IntroTitleBox from '../components/IntroTitleBox';
import Container from '../components/layout/Container';
import MainTitle from '../components/MainTitle';
import Meta from '../components/seo/Meta';
import SrOnlyTitle from '../components/seo/SrOnlyTitle';
import MainLayout from '../layouts/MainLayout';


const Index = () => (
	<MainLayout>
		<Meta
			title="Blog"
		/>

		<SrOnlyTitle>Blog</SrOnlyTitle>

		<IntroTitleBox>
			<Container>
				<MainTitle>
					Píšu blog o&nbsp;svých zkušenostech a&nbsp;důležitých věcech.
				</MainTitle>
			</Container>
		</IntroTitleBox>

		<Container>
			<h2>TODO</h2>
		</Container>
	</MainLayout>
);

Index.propTypes = {};

export default Index;
